import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, first, flatMap, map, tap} from 'rxjs/operators';
import {CustomerReview} from 'src/app/core/api-models/customer-review';
import {Tier} from 'src/app/core/api-models/customization';
import {Product} from 'src/app/core/api-models/menu.model';
import {Customer, Order} from 'src/app/core/api-models/order';
import {CurrentPayment, SearchParam, Transaction, TransactionType} from 'src/app/core/api-models/transaction';
import {HttpClientWrapper} from 'src/app/core/api/http-wrapper.service';
import {DateSelectionType} from 'src/app/core/constants/common.enum';
import {Constants} from 'src/app/core/constants/constants';
import {ProductType} from 'src/app/core/constants/product-config.constants';
import {CreateNotificationParams, NavigationTabId, NotificationParams, ReminderResponse} from 'src/app/core/models/common.model';
import {NavigationService} from 'src/app/core/services/navigation.service';
import {UtilsService} from 'src/app/core/services/utils.service';
import {AppStore} from 'src/app/store/index.reducer';
import {PhysicalStoreService} from 'src/app/store/master-data/physical-store.service';
import {SaleActions, SaleActionTypes} from 'src/app/store/sale/sale.actions';
import {SaleService} from 'src/app/store/sale/sale.service';
import {TransactionItem, UpdateOrderProperty} from '../../core/api-models/transaction-item';

@Injectable()
export class SaleEffects {

  constructor(private actions$: Actions,
              private store: Store<AppStore>,
              private navigation: NavigationService,
              private httpClientWrapper: HttpClientWrapper,
              private physicalStore: PhysicalStoreService) {
  }

  @Effect()
  searchProducts = this.actions$.pipe(
    ofType(SaleActionTypes.SearchProducts),
    debounceTime(300),
    distinctUntilChanged(),
    flatMap((action: SaleActions.SearchProducts) => {
      const searchTerm: string = action.payload.data.searchTerm;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.getSearchedProducts(searchTerm).pipe(
        first(),
        map((data: Product[]) =>
          new SaleActions.SearchProductsSuccess({tabId, data})
        ),
        catchError((error: string) =>
          of(new SaleActions.SearchProductsError({tabId, data: error})))
      );
    }));

  @Effect()
  searchCustomers = this.actions$.pipe(
    ofType(SaleActionTypes.SearchCustomers),
    flatMap((action: SaleActions.SearchCustomers) => {
      const searchTerm: string = action.payload.data.searchTerm;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.getSearchedCustomers(searchTerm).pipe(
        first(),
        map((data: Customer[]) =>
          new SaleActions.SearchCustomersSuccess({tabId, data})
        ),
        catchError((error: string) =>
          of(new SaleActions.SearchCustomersError({tabId, data: error})))
      );
    }));

  @Effect()
  createCustomers = this.actions$.pipe(
    ofType(SaleActionTypes.CreateCustomer),
    flatMap((action: SaleActions.CreateCustomer) => {
      const customer: Partial<Customer> = action.payload.data.customer;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.getCreatedCustomer(customer).pipe(
        first(),
        tap((data: Customer) => {
          if (data?.errorCode !== Constants.duplicatePhone || data?.forceInsert) {
            return this.store.dispatch(
              new SaleActions.CreateCustomerSuccess({tabId, data}));
          }
        }),
        map((data: Customer) => {
          if (data?.errorCode === Constants.duplicatePhone && !data?.forceInsert) {
            return new SaleActions.UpdateTransaction({tabId, data: {duplicateCustomer: data}});
          } else {
            return new SaleActions.UpdateTransaction({tabId, data: {customer: data}});
          }
        }),
        catchError((error: string) =>
          of(new SaleActions.CreateCustomerError({tabId, data: error})))
      );
    }));

  @Effect()
  saveTransaction = this.actions$.pipe(
    ofType(SaleActionTypes.SaveTransaction),
    flatMap((action: SaleActions.SaveTransaction) => {
      const transaction: Transaction = action.payload.data.transaction;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.saveThisTransaction(transaction).pipe(
        first(),
        map((data: Partial<Transaction>) =>
          new SaleActions.SaveTransactionSuccess({tabId, data})
        ),
        catchError((error: string) => {
          return of(new SaleActions.SaveTransactionError({tabId, data: error}));
        })
      );
    }));

  @Effect()
  updateTransactionToServer = this.actions$.pipe(
    ofType(SaleActionTypes.UpdateTransactionToServer),
    flatMap((action: SaleActions.UpdateTransactionToServer) => {
      const transaction: Partial<Transaction<Partial<Order>>> = action.payload.data.transaction;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.updateThisTransaction(transaction).pipe(
        first(),
        map((data: Partial<Transaction>) =>
          new SaleActions.UpdateTransactionToServerSuccess({tabId, data})
        ),
        catchError((error: string) => {
          return of(new SaleActions.UpdateTransactionToServerError({tabId, data: error}));
        })
      );
    }));

  @Effect()
  getProduct = this.actions$.pipe(
    ofType(SaleActionTypes.GetProductInfo),
    flatMap((action: SaleActions.GetProductInfo) => {
      const sku: number = action.payload.data.sku;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.getProductById(sku).pipe(
        first(),
        map((data: Product) =>
          new SaleActions.GetProductInfoSuccess({tabId, data})
        ),
        catchError((error: string) =>
          of(new SaleActions.GetProductInfoError({tabId, data: error})))
      );
    }));

  @Effect()
  updateCustomer = this.actions$.pipe(
    ofType(SaleActionTypes.UpdateCustomer),
    flatMap((action: SaleActions.UpdateCustomer) => {
      const customer: Partial<Customer> = action.payload.data.customer;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.updateCustomerById(customer).pipe(
        first(),
        tap((data: Customer) =>
          this.store.dispatch(
            new SaleActions.UpdateTransaction({tabId, data: {customer: data, isCustomerUpdated: true}}))
        ),
        map((data: Customer) =>
          new SaleActions.UpdateCustomerSuccess({tabId, data})
        ),
        catchError((error: string) =>
          of(new SaleActions.UpdateCustomerError({tabId, data: error})))
      );
    }));

  @Effect()
  getCustomer = this.actions$.pipe(
    ofType(SaleActionTypes.GetCustomer),
    flatMap((action: SaleActions.GetCustomer) => {
      const customerId: number = action.payload.data.customerId;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.getCustomerInfo(customerId).pipe(
        first(),
        tap((data: Customer) =>
          this.store.dispatch(
            new SaleActions.UpdateTransaction({tabId, data: {tabId, customer: data}}))
        ),
        map((data: Customer) =>
          new SaleActions.UpdateCustomerSuccess({tabId, data})
        ),
        catchError((error: string) =>
          of(new SaleActions.UpdateCustomerError({tabId, data: error})))
      );
    }));

  @Effect()
  searchTransaction = this.actions$.pipe(
    ofType(SaleActionTypes.SearchTransactions),
    flatMap((action: SaleActions.SearchTransactions) => {
      const searchTerms: Partial<SearchParam> = action.payload.data.searchParam;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.getSearchedTransaction(searchTerms).pipe(
        first(),
        map((data: Transaction[]) =>
          new SaleActions.SearchTransactionsSuccess({tabId, data})
        ),
        catchError((error: string) =>
          of(new SaleActions.SearchTransactionsError({tabId, data: error})))
      );
    }));

  @Effect()
  updateSelectedOrderTransaction = this.actions$.pipe(
    ofType(SaleActionTypes.UpdateSearchedOrder),
    flatMap((action: SaleActions.UpdateSearchedOrder) => {
      const updateOrderProperty: UpdateOrderProperty = action.payload.data.updateOrderProperty;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.updateOrder(updateOrderProperty).pipe(
        first(),
        tap((data: Order) =>
          this.store.dispatch(
            new SaleActions.UpdateSelectedOrder({tabId, data}))
        ),
        map((data: Order) =>
          new SaleActions.UpdateSearchedOrderSuccess({tabId, data})
        ),
        catchError((error: string) =>
          of(new SaleActions.UpdateSearchedOrderError({tabId, data: error})))
      );
    }));

  @Effect()
  updatedServerOrderTransaction = this.actions$.pipe(
    ofType(SaleActionTypes.UpdateServerTransactionOrder),
    flatMap((action: SaleActions.UpdateServerTransactionOrder) => {
      const updateOrderProperty: UpdateOrderProperty = action.payload.data.updateOrderProperty;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.updateOrder(updateOrderProperty).pipe(
        first(),
        map((data: Order) =>
          new SaleActions.UpdateTransactionOrderSuccess({tabId, data})
        ),
        catchError((error: string) =>
          of(new SaleActions.UpdateTransactionOrderError({tabId, data: error})))
      );
    }));

  @Effect()
  getSelectedOrderTransaction = this.actions$.pipe(
    ofType(SaleActionTypes.GetSelectedOrder),
    flatMap((action: SaleActions.GetSelectedOrder) => {
      const id: number = action.payload.data.id;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.getTransactionById(id).pipe(
        first(),
        map((data: Transaction) =>
          new SaleActions.GetSelectedOrderSuccess({tabId, data})
        ),
        catchError((error: string) => {
          console.error('Error in getSelectedOrderTransaction effect', error);
          return of(new SaleActions.GetSelectedOrderError({tabId, data: error}));
        })
      );
    }));

  @Effect()
  changeTransaction = this.actions$.pipe(
    ofType(SaleActionTypes.ChangeTransaction),
    flatMap((action: SaleActions.ChangeTransaction) => {
      const id: number = action.payload.data.id;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.getTransactionById(id).pipe(
        first(),
        map((data: Transaction) => {
            return new SaleActions.ChangeOrderSuccess({tabId, data});
          }
        ),
        catchError((error: string) =>
          of(new SaleActions.ChangeOrderError({tabId, data: error})))
      );
    }));

  @Effect()
  sendTransactionNotification = this.actions$.pipe(
    ofType(SaleActionTypes.SendTransactionNotification),
    flatMap((action: SaleActions.SendTransactionNotification) => {
      const notification: NotificationParams = action.payload.data.notification;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.sendNotification(notification).pipe(
        first(),
        map((data: { status: any }) => {
            if (data.status && !data.status?.error) {
              return new SaleActions.SendTransactionNotificationSuccess({tabId, data: data.status});
            } else {
              return new SaleActions.SendTransactionNotificationError({tabId, data: true});
            }
          }
        ),
        catchError((error: any) =>
          of(new SaleActions.SendTransactionNotificationError({tabId, data: true})))
      );
    }));

  @Effect()
  createTransactionNotification = this.actions$.pipe(
    ofType(SaleActionTypes.CreateTransactionNotification),
    flatMap((action: SaleActions.CreateTransactionNotification) => {
      const notification: CreateNotificationParams[] = action.payload.data.notification;
      const tabId: NavigationTabId = action.payload.data.tabId;
      return this.createNotification(notification).pipe(
        first(),
        map((data: { status: any }) => {
            if (data.status?.error) {
              return new SaleActions.CreateTransactionNotificationError({tabId, data: true});
            } else {
              return new SaleActions.CreateTransactionNotificationSuccess({tabId, data: data.status});
            }
          }
        ),
        catchError((error: any) =>
          of(new SaleActions.CreateTransactionNotificationError({tabId, data: true})))
      );
    }));

  private getSearchedProducts(searchTerm: string): Observable<Product[]> {
    if (searchTerm === '') {
      return of([]);
    }
    let params: HttpParams = new HttpParams();
    params = params.append('name', searchTerm);
    return this.httpClientWrapper.get<Product[]>(Constants.apiPaths.searchProduct, params)
      .pipe(
        map(products => {
          const activeProducts = products.filter(product => product.posProductType !== ProductType.Inactive);
          activeProducts.map((product: Product) => {
            return UtilsService.updateProduct(product);
          });
          return activeProducts;
        }));
  }

  private getSearchedCustomers(searchTerm: string): Observable<Customer[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('q', searchTerm);
    return this.httpClientWrapper.get<Customer[]>(Constants.apiPaths.searchCustomers, params);
  }

  private getCreatedCustomer(customerInput: Partial<Customer>): Observable<Customer> {
    const employee = this.navigation?.currentEmployee?.fullName || 'No employee';
    return this.httpClientWrapper.post(employee, Constants.apiPaths.getCustomers, customerInput);
  }

  private getProductById(id: number): Observable<Product> {
    return this.httpClientWrapper.get<Product>(`${Constants.apiPaths.getProductInfo}/${id}`)
      .pipe(map(product => {
        if (product?.varitionGroup?.variations?.length > 0) {
          product.varitionGroup.variations = product.varitionGroup.variations.map(variation => {
            if ((variation.variationType === 'S' || variation.variationType === 'F')
              && variation?.name?.indexOf(' - ') > -1) {
              variation.name = variation?.name?.substr(0, variation?.name?.indexOf(' - '));
            }
            return variation;
          });
        }
        return product;
      }));
  }

  private saveThisTransaction(transaction: Transaction): Observable<Partial<Transaction>> {
    const currentUser = transaction && transaction.employee
      && transaction.employee.fullName && transaction.employee.fullName || '';

    // If transaction id is present, transaction has been saved earlier, change it to put request
    const isChange = transaction.transactionId && transaction.transactionId > 0;

    // Update createTime for the transaction
    const trans: Transaction = JSON.parse(JSON.stringify(transaction));
    trans.createTime = moment().format('YYYY-MM-DDTHH:mm:ss.sss');

    if (isChange) {
      return this.httpClientWrapper.put<Partial<Transaction>, Partial<Transaction>>(currentUser,
        (Constants.apiPaths.quickSale), transaction);
    } else {
      return this.httpClientWrapper.post<Transaction, Partial<Transaction>>(currentUser,
        (Constants.apiPaths.quickSale), trans);
    }
  }

  public updateThisTransaction(transaction: Partial<Transaction<Partial<Order>>>): Observable<Partial<Transaction>> {
    const currentUser = transaction && transaction.employee
      && transaction.employee.fullName && transaction.employee.fullName || '';
    return this.httpClientWrapper.put<Partial<Transaction<Partial<Order>>>, Partial<Transaction>>(currentUser,
      (Constants.apiPaths.quickSale), transaction);
  }

  updateCustomerById(customer: Partial<Customer>): Observable<Customer> {
    const employee = this.navigation?.currentEmployee?.fullName || 'No employee';
    return this.httpClientWrapper.put(employee, `${Constants.apiPaths.updateCustomers}/${customer.id}`, customer);
  }

  getCustomerInfo(customerId: number): Observable<Customer> {
    return this.httpClientWrapper.get(`${Constants.apiPaths.updateCustomers}/${customerId}`);
  }

  public getSearchedTransaction(searchTerms: Partial<SearchParam>): Observable<Transaction[]> {
    let params: HttpParams = new HttpParams();
    const isOrder = searchTerms?.transactionType === TransactionType.ORDER;

    // If status page then we just allow search on order numbers
    if (searchTerms.isStatusPage &&
      (!searchTerms?.search
        || (searchTerms?.search && !parseInt(searchTerms?.search, 10))
        || (searchTerms?.search && searchTerms?.search.length > 6))) {
      return;
    }

    // If clear is called, don't call backend
    if (!searchTerms || searchTerms?.clear) {
      return of([]);
    }

    // If no search and no date is entered, then change the search to today
    if (!searchTerms?.search && !searchTerms?.dateRange?.from) {
      const today = new Date();
      params = params.append('fromDate', moment(today).format('YYYY-MM-DD'));
      // If fulfillmentBy is empty, change it to current store
      if (!searchTerms?.fulfillmentBy) {
        params = params.append('fulfillmentBy', this.physicalStore.currentStore.storeNumber.toString());
      }
    }

    if (searchTerms?.search) {
      params = params.append('search', searchTerms?.search);
    }

    if(searchTerms?.isHistorical) {
      params = params.append('isHistorical', searchTerms?.isHistorical);
    }

    if (searchTerms?.orderAt) {
      params = params.append('orderAt', searchTerms?.orderAt);
    }

    if (searchTerms?.status && searchTerms?.status.length > 0) {
      params = params.append('status', searchTerms?.status.concat(',').toString());
    }

    if (searchTerms?.dateRange?.from) {
      params = params.append('fromDate', moment(searchTerms?.dateRange?.from).format('YYYY-MM-DD'));
    }

    if (searchTerms?.paymentMethod) {
      params = params.append('paymentMethod', searchTerms?.paymentMethod);
    }

    if (searchTerms?.deposit) {
      params = params.append('deposit', searchTerms?.deposit);
    }

    // If to date is null, send from date in the query
    if (searchTerms?.dateRange?.to) {
      params = params.append('toDate', moment(searchTerms?.dateRange?.to).format('YYYY-MM-DD 23:59:59'));
    } else if (searchTerms?.dateRange?.from) {
      params = params.append('toDate', moment(searchTerms?.dateRange?.from).format('YYYY-MM-DD 23:59:59'));
    }

    if (searchTerms?.transactionType && searchTerms?.transactionType.length > 0) {
      params = params.append('transactionType', searchTerms?.transactionType);
    }

    if (isOrder && searchTerms?.orderStatus && searchTerms?.orderStatus.length > 0) {
      params = params.append('orderStatus', searchTerms?.orderStatus.join(','));
    }

    if (isOrder && searchTerms?.tags && searchTerms?.tags.length > 0) {
      params = params.append('tags', searchTerms?.tags.join(','));
    }

    if (isOrder && searchTerms?.fulfillmentBy) {
      params = params.append('fulfillmentBy', searchTerms?.fulfillmentBy);
    }

    // If no date range type is selected, send fulfillment date type as default
    if (isOrder) {
      if (searchTerms?.dateRangeType) {
        params = params.append('dateRangeType', searchTerms?.dateRangeType);
      } else {
        params = params.append('dateRangeType', DateSelectionType.FulfillmentDate);
      }
    }

    // ToDo: Jaison Please add code for adding delivery tier and photo cake flags
    return this.httpClientWrapper.get<Transaction[]>(Constants.apiPaths.searchTransaction, params);
  }

  public updateOrder(updateOrderProperty: UpdateOrderProperty) {
    return this.httpClientWrapper.put(updateOrderProperty.employee.fullName,
      `${Constants.apiPaths.updateOrderOnly}/${updateOrderProperty.orderId}`, updateOrderProperty.updateProperty);
  }

  sendNotification(notification: NotificationParams): Observable<{ status: any }> {
    return this.httpClientWrapper.post(notification.employee.fullName, Constants.apiPaths.sendNotification, notification);
  }

  public createNotification(notification: CreateNotificationParams[]): Observable<{ status: any }> {
    const employee = notification.length ? notification[0].employee : '';
    return this.httpClientWrapper.post(employee, Constants.apiPaths.createNotification, notification);
  }

  public sendReminders(notification: CreateNotificationParams[]): Observable<ReminderResponse[]> {
    const employee = notification.length ? notification[0].employee : '';
    return this.httpClientWrapper.post(employee, Constants.apiPaths.reminders, notification);
  }

  // This bypasses the store, so use only where store should not be used
  public getTransactionDirect(id: number): Observable<Transaction> {
    id = +(id.toString().replace('-', ''));
    return this.httpClientWrapper.get<Transaction>(`${Constants.apiPaths.transactions}/${id}`);
  }

  // This bypasses the store, so use only where store should not be used
  public getShopifyTransactionDirect(id: number): Observable<Transaction> {
    id = +(id.toString().replace('-', ''));
    return this.httpClientWrapper.get<Transaction>(`${Constants.apiPaths.shopifyOrders}/${id}`);
  }

  private getTransactionById(id: number): Observable<Transaction> {
    return this.httpClientWrapper.get<Transaction>(`${Constants.apiPaths.transactions}/${id}`)
      .pipe(
        map(transaction => {
          const transactionItems: TransactionItem[] =
            // tslint:disable-next-line:max-line-length
            this.updateTransactionAndItems(transaction, transaction.transactionItems)?.transactionItems?.sort((a: TransactionItem, b: TransactionItem) =>
              a.sku < b.sku ? -1 : a.sku > b.sku ? 1 : 0);
          const currentPayment: CurrentPayment = {
            ...SaleService.initialCurrentPayment(),
            previousAmountDue: +transaction.amountDue,
            previousDeposit: +transaction.amountPaid,
            previousBalance: +transaction.balance,
            previousRounding: +transaction.rounding
          };

          return {
            ...transaction,
            transactionItems,
            currentPayment
          };
        })
      );
  }

  private updateTransactionAndItems(transaction: Transaction, transactionItems: TransactionItem[]): Transaction {
    const transItems: TransactionItem[] = transactionItems.map((item: TransactionItem) => {
      if (item?.customization?.fondantCake || item?.customization?.fondantDecorations) {
        transaction.hasFondantCake = true;
      }

      if (item?.customization?.tiers?.length) {
        item.customization.tiers = item.customization.tiers.map((tier: Tier) => {
          if (tier?.size?.name?.lastIndexOf('- ') > -1) {
            tier.size.name = tier.size?.name.substr(0, tier.size?.name.lastIndexOf('- ')).trim();
          }
          if (tier?.size?.name?.lastIndexOf(' - ') > -1) {
            tier.size.name = tier.size?.name.substr(0, tier.size?.name.lastIndexOf(' - ')).trim();
          }

          if (tier?.flavour?.name?.lastIndexOf('- ') > -1) {
            tier.flavour.name = tier.flavour?.name.substr(0, tier.flavour?.name.lastIndexOf('- ')).trim();
          }
          if (tier?.flavour?.name?.lastIndexOf(' - ') > -1) {
            tier.flavour.name = tier.flavour?.name.substr(0, tier.flavour?.name.lastIndexOf(' - ')).trim();
          }
          // if (tier?.sponge?.name?.toLowerCase().indexOf('gluten') > -1) {
          //   transaction.isGlutenFree = true;
          // }
          // if (tier?.sponge?.name?.toLowerCase().indexOf('vegan') > -1
          //   && tier?.sponge?.name?.toLowerCase().indexOf('gluten') === -1) {
          //   transaction.isVegan = true;
          // }
          return tier;
        });
      }
      return {
        ...item
      };
    });

    return {
      ...transaction,
      transactionItems: transItems
    };
  }

  // This bypasses the store, so use only where store should not be used
  public saveCustomerReview(customerReview: CustomerReview): Observable<CustomerReview> {
    return this.httpClientWrapper.post(customerReview.name,
      `${Constants.apiPaths.customerReview}`, customerReview);
  }

  // This bypasses the store, so use only where store should not be used
  public updateCustomerReview(id: number, customerReview: CustomerReview): Observable<CustomerReview> {
    return this.httpClientWrapper.put(customerReview.name,
      `${Constants.apiPaths.customerReview}/${id}`, customerReview);
  }

}
